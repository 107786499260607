import React from 'react';

export enum FetchStatus {
  FETCHING,
  IDLE,
  FAILED,
}

export enum PlayerCategory {
  FAVORITE = 1,
  FOREIGNER = 2,
  AMERICAN = 3,
  LONG_SHORT = 4,
}

export enum Pool {
  SOCAL = 'SoCal',
}

export enum CellType {
  ENTRY_CELL,
  PLAYER_CELL,
  TO_PAR_CELL,
  TODAY_CELL,
  THRU_CELL,
}

export interface LeaderboardTableColumn {
  align?: 'left' | 'right';
  bold?: boolean;
  cellType: CellType;
  dataKey: string;
  headerLabel: string;
  id: string;
  playerIndex?: number;
  sortable?: boolean;
  width: number;
}

export interface LeaderboardDTO {
  [s: string]: LeaderboardEntry[];
}

export interface LeaderboardEntry {
  ListPosition: number;
  Players: Player[];
  Position: string;
  TeamEntry: string;
  TeamName: string;
  TotalPayout: number;
  TotalPayoutDisplay: string;
}

export interface Player {
  ListPosition: number;
  Name: string;
  Payout: number;
  PayoutDisplay: string;
  PlayerCat: number;
  PlayerId: number;
  Position: string;
  Status: string;
  Thru: number;
  ThruScore: string;
  Today: number;
  TodayScore: string;
  ToPar: number;
  ToParScore: string;
}

export interface Toggle {
  on: boolean;
  set: (on: boolean) => void;
  toggle: () => void;
}

export interface LeaderboardCellProps {
  align?: 'left' | 'right';
  bold?: boolean;
  dark?: boolean;
  content?: React.ReactNode;
  key: string;
  width: number;
  style: React.CSSProperties;
}

export interface LeaderboardPlayerCellProps {
  player: Player;
}
