import React, { useState } from 'react';

import {
  AppBar,
  Container,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

// import { LeaderboardTable } from './Leaderboard/LeaderboardTable';
import { getLeaderboardTableWidth } from '../util/table';
import { LeaderboardTableDesktop } from './Leaderboard/LeaderboardTableDesktop';
import { darkestGreen } from '../style/color';
import { TournamentName } from '../util/constants';
import { LastUpdated } from './Leaderboard/LastUpdated';

const useStyles = makeStyles({
  rootContainer: {
    backgroundColor: darkestGreen,
    maxWidth: getLeaderboardTableWidth(),
    padding: 20,
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  title: {
    flexGrow: 1,
  },
  mainContentContainer: {
    flexGrow: 1,
    marginTop: 65,
  },
  lastUpdated: {
    paddingBottom: 5,
    textAlign: 'center',
  },
});

export const App: React.FC = () => {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const toggleDrawer = (): void => {
    setShowDrawer(!showDrawer);
  };

  return (
    <Container className={classes.rootContainer}>
      <Grid>
        <AppBar>
          <Toolbar>
            <IconButton onClick={toggleDrawer} edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer}>
              <List>
                <ListItem>
                  <Typography>Version: {process.env.REACT_APP_VERSION}</Typography>
                </ListItem>
              </List>
            </Drawer>
            <Typography className={classes.title} variant="h6">
              Cool Golf Pool (V2)
            </Typography>
            <div className={classes.lastUpdated}>
              <LastUpdated />
            </div>
          </Toolbar>
        </AppBar>
        <Grid className={classes.mainContentContainer} item xs={12}>
          {/* TODO: The tournament name / info should come from an api
                     we can build navigation around that */}
          <LeaderboardTableDesktop tournamentName={TournamentName.TheOpen2021} />
          {/* TODO: Need a mobile friendly table. */}
        </Grid>
      </Grid>
    </Container>
  );
};
