import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { DateTime } from 'luxon';
import { useAppSelector } from '../../store/hooks';
import { getLeaderboardLastUpdated } from '../../store/leaderboard/selectors';

const useStyles = makeStyles({
  lastUpdated: {
    fontStyle: 'italic',
    fontSize: 12,
  },
});

export const LastUpdated: React.FC = () => {
  const classes = useStyles();
  const lastUpdated = useAppSelector(getLeaderboardLastUpdated);

  return lastUpdated ? (
    <Typography className={classes.lastUpdated}>
      {lastUpdated.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
    </Typography>
  ) : null;
};
