/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Fab,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';

import { AutoSizer, MultiGrid, GridCellProps, ScrollParams } from 'react-virtualized';
import { FilterList } from '@material-ui/icons';
import clsx from 'clsx';
import { leaderboardTableColumns, playerDataCellMap, TournamentName } from '../../util/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchLeaderboard } from '../../store/leaderboard/actions';
import {
  CellType,
  FetchStatus,
  LeaderboardEntry,
  LeaderboardPlayerCellProps,
  Player,
} from '../../util/types';
import { darkGreen } from '../../style/color';
import { FilterData, FilterDrawer } from './FilterDrawer';
import theme from '../../theme';
import { MultiSelect } from '../Inputs/MultiSelect';
import {
  getLeaderboard,
  // getLeaderboardPlayerNames,
  getLeaderboardStatus,
  getLeaderboardTeamNames,
} from '../../store/leaderboard/selectors';

import { useToggle } from '../../hooks/toggle';
import { useFilteredLeaderboardEntries } from '../../hooks/leaderboard';
import { FabPosition, RefreshLeaderboardFab } from './RefreshLeaderboardFab';
import { EntryCell } from './EntryCell';
import { HeaderCell } from './HeaderCell';
import { PlayerCell } from './PlayerCell';
import { TournamentLogo } from './TournamentLogo';

const useStyles = makeStyles({
  leaderboardTableContainer: {
    height: '90vh',
    overflowX: 'auto',
    flexGrow: 1,
  },
  fullHeight: {
    height: '95vh',
  },
  filterContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },
  filterInput: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  shadowRight: {
    boxShadow: '4px 0 5px -3px #888',
    zIndex: 100,
  },
  shadowBottom: {
    boxShadow: '0 4px 5px -3px #888',
    zIndex: 200,
  },
  tableRow: {
    borderBottom: '1px solid lightgray',
  },
});

const ROW_HEIGHT = 48;

interface LeaderboardTableDesktopProps {
  tournamentName: TournamentName;
}

export const LeaderboardTableDesktop: React.FC<LeaderboardTableDesktopProps> = ({
  tournamentName,
}) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const fixedColumnCount = useMediaQuery(theme.breakpoints.down('xs')) ? 0 : 4;

  const leaderboardEntries = useAppSelector(getLeaderboard);
  const leaderboardStatus = useAppSelector(getLeaderboardStatus);
  const [filterData, setFilterData] = useState<FilterData>({
    selectedTeamNames: [],
  });

  const filteredLeaderboardEntries = useFilteredLeaderboardEntries(leaderboardEntries, filterData);

  const { on: showFilters, toggle: toggleFilters } = useToggle(false);
  const { on: showHeaderShadow, set: setShowHeaderShadow } = useToggle(false);
  const { on: showLeftColumnShadow, set: setShowLeftColumnShadow } = useToggle(false);

  const multiGridRef = useRef<MultiGrid>(null);

  /**
   * Fetch the leaderboard data when the component first mounts.
   */
  useEffect(() => {
    dispatch(fetchLeaderboard(tournamentName));
  }, []);

  /**
   * Update with the latest filter information.
   */
  const handleApplyFilters = (updatedFilterData: FilterData): void => {
    const { selectedPlayerNames, selectedTeamNames } = updatedFilterData;
    setFilterData({
      selectedPlayerNames: selectedPlayerNames || filterData.selectedPlayerNames,
      selectedTeamNames: selectedTeamNames || filterData.selectedTeamNames,
    });
  };

  /**
   * Determine whether or not to show the shadows in the grid as the user
   * scrolls around the fixed rows and columns.
   */
  const onGridScroll = ({ scrollTop, scrollLeft }: ScrollParams): void => {
    setShowHeaderShadow(scrollTop > 0);
    setShowLeftColumnShadow(scrollLeft > 0);
  };

  /**
   * React virtualized has no good way of knowing when to
   * adjust rendering sizes, so we need to call this manually.
   */
  const onGridResize = (): void => {
    multiGridRef.current?.recomputeGridSize();
  };

  /**
   * Renders a cell in the header row of the table.
   */
  const renderHeaderCell = (
    columnIndex: number,
    rowIndex: number,
    style: React.CSSProperties,
  ): React.ReactNode => {
    const { headerLabel } = leaderboardTableColumns[columnIndex];
    return (
      <HeaderCell
        content={headerLabel}
        key={`header_${rowIndex}_${columnIndex}`}
        style={style}
        {...leaderboardTableColumns[columnIndex]}
      />
    );
  };

  /**
   * Renders an entry cell in the table. Entry cells are the left fixed
   * columns that correspond to the high level entry data (team name, total earnings, etc.)
   */
  const renderEntryCell = (
    columnIndex: number,
    rowIndex: number,
    style: React.CSSProperties,
  ): React.ReactNode => {
    const leaderboardTableColumn = leaderboardTableColumns[columnIndex];
    const leaderboardEntry = filteredLeaderboardEntries[rowIndex - 1] as LeaderboardEntry;
    const { dataKey } = leaderboardTableColumn;

    return (
      <EntryCell key={`entry_${rowIndex}_${columnIndex}`} style={style} {...leaderboardTableColumn}>
        {leaderboardEntry[dataKey as keyof LeaderboardEntry]}
      </EntryCell>
    );
  };

  /**
   * Renders a player cell. Player cells are the main table content, the individual
   * player data for each entry (player name, earnings, to par, today, etc.)
   */
  const renderPlayerCell = (
    columnIndex: number,
    rowIndex: number,
    style: React.CSSProperties,
  ): React.ReactNode => {
    const leaderboardTableColumn = leaderboardTableColumns[columnIndex];
    const { cellType, dataKey, playerIndex } = leaderboardTableColumn;

    const leaderboardEntry = filteredLeaderboardEntries[rowIndex - 1] as LeaderboardEntry;
    const index = Number(playerIndex);
    const player = leaderboardEntry.Players[index];
    const data = player[dataKey as keyof Player];

    const PlayerDataCell: React.FC<LeaderboardPlayerCellProps> | undefined =
      playerDataCellMap[cellType];

    return (
      <PlayerCell
        key={`player_${rowIndex}_${columnIndex}`}
        dark={index % 2 !== 0}
        style={style}
        {...leaderboardTableColumn}
      >
        {PlayerDataCell ? <PlayerDataCell player={player}>{data}</PlayerDataCell> : data}
      </PlayerCell>
    );
  };

  const renderCell = ({ columnIndex, rowIndex, style }: GridCellProps): React.ReactNode => {
    const { cellType } = leaderboardTableColumns[columnIndex];

    // Header Cell
    if (rowIndex === 0) {
      return renderHeaderCell(columnIndex, rowIndex, style);
    }

    // Check for no data
    if (filteredLeaderboardEntries.length <= 0) {
      return null;
    }

    // Fixed left Entry Cells
    if (cellType === CellType.ENTRY_CELL) {
      return renderEntryCell(columnIndex, rowIndex, style);
    }

    // Moveable right Player Data Cells
    return renderPlayerCell(columnIndex, rowIndex, style);
  };

  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <Paper
          className={clsx(classes.leaderboardTableContainer, {
            [classes.fullHeight]: useMediaQuery(theme.breakpoints.down('md')),
          })}
          elevation={3}
        >
          <Hidden mdDown>
            <Grid container className={classes.filterContainer} justify="space-between">
              <Grid item>
                <TournamentLogo />
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  {showFilters && (
                    <>
                      <Grid item>
                        <Paper className={classes.filterInput} elevation={1}>
                          <MultiSelect
                            label="Filter Team Names"
                            onChange={(selectedTeamNames) =>
                              handleApplyFilters({ selectedTeamNames })
                            }
                            selector={getLeaderboardTeamNames}
                          />
                        </Paper>
                      </Grid>
                      {/* <Grid item>
                        <Paper className={classes.filterInput} elevation={1}>
                          <MultiSelect
                            label="Filter Players"
                            onChange={(selectedPlayerNames) =>
                              handleApplyFilters({ selectedPlayerNames })
                            }
                            selector={getLeaderboardPlayerNames}
                          />
                        </Paper>
                      </Grid> */}
                    </>
                  )}
                  <Grid item>
                    <Fab
                      color="secondary"
                      onClick={toggleFilters}
                      disabled={leaderboardStatus === FetchStatus.FETCHING}
                      title="Show filters"
                    >
                      <FilterList />
                    </Fab>
                  </Grid>
                  <Grid item>
                    <RefreshLeaderboardFab tournamentName={tournamentName} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <AutoSizer onResize={onGridResize}>
            {({ height, width }) => (
              <MultiGrid
                classNameBottomLeftGrid={showLeftColumnShadow ? classes.shadowRight : ''}
                classNameTopLeftGrid={clsx({
                  [classes.shadowBottom]: showHeaderShadow,
                  [classes.shadowRight]: showLeftColumnShadow,
                })}
                classNameTopRightGrid={showHeaderShadow ? classes.shadowBottom : ''}
                cellRenderer={renderCell}
                columnCount={leaderboardTableColumns.length}
                columnWidth={({ index }) => leaderboardTableColumns[index].width}
                enableFixedColumnScroll
                estimatedColumnSize={75}
                estimatedRowSize={500}
                fixedColumnCount={fixedColumnCount}
                fixedRowCount={1}
                height={height}
                noContentRenderer={() => <CircularProgress style={{ color: darkGreen }} />}
                onScroll={onGridScroll}
                overscanColumnCount={16}
                overscanRowCount={50}
                ref={multiGridRef}
                rowCount={filteredLeaderboardEntries.length + 1}
                rowHeight={ROW_HEIGHT}
                width={width}
              />
            )}
          </AutoSizer>
          <Hidden lgUp>
            <FilterDrawer onApplyFilters={handleApplyFilters} />
            <RefreshLeaderboardFab
              tournamentName={tournamentName}
              fabPosition={FabPosition.BOTTOM_LEFT}
            />
          </Hidden>
        </Paper>
      </Grid>
    </Grid>
  );
};
