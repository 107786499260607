import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { LeaderboardPlayerCellProps } from '../../util/types';
import { yellow } from '../../style/color';
import { isPlayerFinished } from '../../util/player';

const useStyles = makeStyles({
  finished: {
    display: 'inline-block',
    color: yellow,
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
});

export const ScoreThru: React.FC<LeaderboardPlayerCellProps> = ({ player }) => {
  const classes = useStyles();
  const { ThruScore } = player;

  return (
    <Typography className={clsx({ [classes.finished]: isPlayerFinished(player) })}>
      {ThruScore}
    </Typography>
  );
};
