import { useState } from 'react';
import { Toggle } from '../util/types';

export const useToggle = (initial = false): Toggle => {
  const [on, set] = useState<boolean>(initial);

  const toggle = (): void => {
    set(!on);
  };

  return {
    on,
    set,
    toggle,
  };
};
