import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { LeaderboardPlayerCellProps } from '../../util/types';
import { green, red } from '../../style/color';
import { isPlayerFinished } from '../../util/player';

const useStyles = makeStyles({
  score: {
    display: 'inline-block',
  },
  evenPar: {
    color: green,
  },
  underPar: {
    color: red,
  },
  finished: {
    fontWeight: 'bold',
  },
});

export const ScoreToday: React.FC<LeaderboardPlayerCellProps> = ({ player }) => {
  const classes = useStyles();
  const { Today, TodayScore } = player;

  let colorClass = '';

  if ((!Today && TodayScore === 'E') || Today === 0) {
    colorClass = classes.evenPar;
  } else if (Today < 0) {
    colorClass = classes.underPar;
  }

  return (
    <Typography
      className={clsx(classes.score, colorClass, { [classes.finished]: isPlayerFinished(player) })}
    >
      {TodayScore}
    </Typography>
  );
};
