/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Drawer, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import theme from '../../theme';
import { useAppSelector } from '../../store/hooks';
import {
  // getLeaderboardPlayerNames,
  getLeaderboardStatus,
  getLeaderboardTeamNames,
} from '../../store/leaderboard/selectors';
import { FetchStatus } from '../../util/types';
import { MultiSelect } from '../Inputs/MultiSelect';

const useStyles = makeStyles({
  drawerContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 50,
    paddingTop: 20,
  },
  formContainer: {
    paddingBottom: 30,
  },
  filterFab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  select: {
    minWidth: 100,
  },
});

export interface FilterData {
  selectedTeamNames?: string[];
  selectedPlayerNames?: string[];
}

interface Props {
  onApplyFilters: (filterData: FilterData) => void;
}

export const FilterDrawer: React.FC<Props> = ({ onApplyFilters }) => {
  const classes = useStyles();
  const leaderboardStatus = useAppSelector(getLeaderboardStatus);

  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);

  const openFilterDrawer = (): void => {
    setShowFilterDrawer(true);
  };

  const closeFilterDrawer = (): void => {
    setShowFilterDrawer(false);
  };

  const handleTeamNameFilterChange = (selectedTeamNames: string[]) => {
    onApplyFilters({ selectedTeamNames });
  };

  // const handlePlayerNameFilterChange = (selectedPlayerNames: string[]) => {
  //   onApplyFilters({ selectedPlayerNames });
  // };

  return (
    <>
      <Fab
        color="primary"
        className={classes.filterFab}
        onClick={openFilterDrawer}
        disabled={leaderboardStatus === FetchStatus.FETCHING}
      >
        <FilterList />
      </Fab>
      <Drawer
        anchor="bottom"
        open={showFilterDrawer}
        elevation={3}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Grid container spacing={2} className={classes.drawerContainer}>
          {/* Title Row */}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5">Filters</Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Form Row */}
          <Grid item xs={12}>
            <Grid container className={classes.formContainer} spacing={2} justify="center">
              <Grid item>
                <MultiSelect
                  label="Filter Team Names"
                  onChange={handleTeamNameFilterChange}
                  selector={getLeaderboardTeamNames}
                />
              </Grid>
              {/* <Grid item>
                <MultiSelect
                  label="Filter Players"
                  onChange={handlePlayerNameFilterChange}
                  selector={getLeaderboardPlayerNames}
                />
              </Grid> */}
            </Grid>
          </Grid>
          {/* Action Row */}
          <Grid item xs={12}>
            <Grid container spacing={6} justify="center">
              <Grid item>
                <Button color="primary" onClick={closeFilterDrawer}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};
