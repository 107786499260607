/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { FetchStatus, LeaderboardEntry, Pool } from '../../util/types';
import { fetchLeaderboard } from './actions';

interface LeaderboardState {
  status: FetchStatus;
  leaderboardEntries: LeaderboardEntry[];
  lastUpdated: DateTime | null;
}

const initialState: LeaderboardState = {
  status: FetchStatus.IDLE,
  leaderboardEntries: [],
  lastUpdated: null,
};

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    noop: (state) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaderboard.pending, (state) => {
        state.status = FetchStatus.FETCHING;
      })
      .addCase(fetchLeaderboard.fulfilled, (state, action) => {
        state.status = FetchStatus.IDLE;
        state.leaderboardEntries = action.payload[Pool.SOCAL];
        state.lastUpdated = DateTime.now();
      });
  },
});

export const { noop } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
