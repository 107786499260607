import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { darkGreen } from '../../style/color';
import { LeaderboardCellProps } from '../../util/types';

const useStyles = makeStyles({
  headerCell: {
    alignItems: 'center',
    backgroundColor: darkGreen,
    color: 'white',
    display: 'flex',
    flex: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 10,
  },
  alignRight: {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  alignLeft: {
    justifyContent: 'flex-start',
    textAlign: 'right',
  },
});

export const HeaderCell: React.FC<LeaderboardCellProps> = ({
  align,
  key,
  content,
  width,
  style,
}) => {
  const classes = useStyles();
  const alignClass = align === 'right' ? classes.alignRight : classes.alignLeft;

  return (
    <span
      className={clsx(classes.headerCell, alignClass)}
      key={`${key}`}
      style={{ width, ...style }}
    >
      {content}
    </span>
  );
};
