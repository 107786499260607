import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { LeaderboardPlayerCellProps } from '../../util/types';
import { green, red } from '../../style/color';
import { isPlayerFinished } from '../../util/player';

const useStyles = makeStyles({
  score: {
    display: 'inline-block',
  },
  evenPar: {
    color: green,
  },
  underPar: {
    color: red,
  },
  finished: {
    fontWeight: 'bold',
  },
});

export const ScoreToPar: React.FC<LeaderboardPlayerCellProps> = ({ player }) => {
  const classes = useStyles();
  const { ToPar, ToParScore } = player;

  let colorClass = '';

  if (ToParScore === '-') {
    colorClass = '';
  } else if (ToPar < 0) {
    colorClass = classes.underPar;
  } else if (ToPar === 0 || ToParScore === 'E') {
    colorClass = classes.evenPar;
  }

  return (
    <Typography
      className={clsx(classes.score, colorClass, { [classes.finished]: isPlayerFinished(player) })}
    >
      {ToParScore}
    </Typography>
  );
};
