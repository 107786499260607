import { FilterData } from '../components/Leaderboard/FilterDrawer';
import { LeaderboardEntry } from '../util/types';

export const useFilteredLeaderboardEntries = (
  leaderboardEntries: LeaderboardEntry[],
  filterData: FilterData,
): LeaderboardEntry[] => {
  const { selectedTeamNames = [], selectedPlayerNames = [] } = filterData;
  const hasTeamNameFilter = selectedTeamNames.length > 0;
  const hasPlayerNameFilter = selectedPlayerNames.length > 0;

  if (hasTeamNameFilter || hasPlayerNameFilter) {
    return leaderboardEntries.filter((leaderboardEntry) => {
      let containsTeamName = true;
      let containsPlayerName = true;

      if (hasTeamNameFilter) {
        containsTeamName = selectedTeamNames.indexOf(leaderboardEntry.TeamName) !== -1;
      }
      if (hasPlayerNameFilter) {
        const entryPlayerNames = leaderboardEntry.Players.map((player) => player.Name);

        containsPlayerName = selectedPlayerNames.some((playerName) =>
          entryPlayerNames.includes(playerName),
        );
      }

      return containsTeamName && containsPlayerName;
    });
  }

  return leaderboardEntries;
};
