import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { lightestGreen } from '../../style/color';
import { LeaderboardCellProps } from '../../util/types';

const useStyles = makeStyles({
  playerCell: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingLeft: 5,
    paddingRight: 10,
    borderBottom: '1px solid lightgray',
  },
  dark: {
    backgroundColor: lightestGreen,
  },
  alignRight: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  alignLeft: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
});

export const PlayerCell: React.FC<LeaderboardCellProps> = ({
  align,
  children,
  dark,
  style,
  width,
}) => {
  const classes = useStyles();
  const alignClass = align === 'right' ? classes.alignRight : classes.alignLeft;

  return (
    <span
      className={clsx(classes.playerCell, alignClass, { [classes.dark]: dark })}
      style={{ width, ...style }}
    >
      {children}
    </span>
  );
};
