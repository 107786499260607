import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { LeaderboardDTO } from '../../util/types';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export const fetchLeaderboard = createAsyncThunk(
  'leaderboard/fetchLeaderboard',
  async (tournamentName: string) => {
    const url = `${API_BASE_URL}/leaderboard?tournamentName=${encodeURIComponent(tournamentName)}`;

    const { data } = await axios.get<LeaderboardDTO>(url);

    return data;
  },
);
