import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { lightGreen } from '../../style/color';
import { LeaderboardCellProps } from '../../util/types';

const useStyles = makeStyles({
  entryCell: {
    backgroundColor: lightGreen,
    paddingLeft: 5,
    paddingRight: 10,
    borderBottom: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  alignRight: {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  alignLeft: {
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
});

export const EntryCell: React.FC<LeaderboardCellProps> = ({
  align,
  bold,
  children,
  width,
  style,
}) => {
  const classes = useStyles();
  const alignClass = align === 'right' ? classes.alignRight : classes.alignLeft;

  return (
    <span
      className={clsx(classes.entryCell, alignClass, { [classes.bold]: bold })}
      style={{ width, ...style }}
    >
      {children}
    </span>
  );
};
