import { ScoreThru } from '../components/Leaderboard/ScoreThru';
import { ScoreToday } from '../components/Leaderboard/ScoreToday';
import { ScoreToPar } from '../components/Leaderboard/ScoreToPar';
import { CellType, LeaderboardPlayerCellProps, LeaderboardTableColumn } from './types';

export enum TournamentName {
  DemoTournament = 'demo_tournament',
  TheOpen2021 = 'the_open_2021',
}

export const playerDataCellMap: { [key in CellType]?: React.FC<LeaderboardPlayerCellProps> } = {
  [CellType.TO_PAR_CELL]: ScoreToPar,
  [CellType.TODAY_CELL]: ScoreToday,
  [CellType.THRU_CELL]: ScoreThru,
};

const PLAYER_CATEGORIES = ['Favorite', 'Foreigner', 'American', 'Long Shot'];
const COL_WIDTH_SMALL = 50;
const COL_WIDTH_LARGE = 100;

const entryCells: LeaderboardTableColumn[] = [
  {
    id: 'Position',
    cellType: CellType.ENTRY_CELL,
    headerLabel: 'Pos',
    dataKey: 'Position',
    width: COL_WIDTH_SMALL,
  },
  {
    id: 'teamName',
    cellType: CellType.ENTRY_CELL,
    headerLabel: 'Name',
    dataKey: 'TeamName',
    sortable: true,
    width: COL_WIDTH_LARGE,
    bold: true,
  },
  {
    id: 'entryNumber',
    cellType: CellType.ENTRY_CELL,
    headerLabel: 'Entry',
    dataKey: 'TeamEntry',
    width: COL_WIDTH_SMALL,
  },
  {
    id: 'totalEarnings',
    cellType: CellType.ENTRY_CELL,
    headerLabel: 'Total Earnings',
    dataKey: 'TotalPayoutDisplay',
    width: COL_WIDTH_LARGE,
    align: 'right',
    bold: true,
  },
];

const createPlayerCells = (playerIndex: number): LeaderboardTableColumn[] => [
  {
    id: 'position',
    cellType: CellType.PLAYER_CELL,
    headerLabel: 'Pos',
    dataKey: 'Position',
    playerIndex,
    width: COL_WIDTH_SMALL,
  },
  {
    id: 'name',
    cellType: CellType.PLAYER_CELL,
    headerLabel: PLAYER_CATEGORIES[playerIndex],
    dataKey: 'Name',
    playerIndex,
    width: COL_WIDTH_LARGE,
  },
  {
    id: 'earnings',
    cellType: CellType.PLAYER_CELL,
    headerLabel: 'Earnings',
    dataKey: 'PayoutDisplay',
    playerIndex,
    width: COL_WIDTH_LARGE,
    align: 'right',
  },
  {
    id: 'toPar',
    cellType: CellType.TO_PAR_CELL,
    headerLabel: 'To Par',
    dataKey: 'ToPar',
    playerIndex,
    width: COL_WIDTH_SMALL,
    align: 'right',
  },
  {
    id: 'today',
    cellType: CellType.TODAY_CELL,
    headerLabel: 'Today',
    dataKey: 'Today',
    playerIndex,
    width: COL_WIDTH_SMALL,
    align: 'right',
  },
  {
    id: 'thru',
    cellType: CellType.THRU_CELL,
    headerLabel: 'Thru',
    dataKey: 'ThruScore',
    playerIndex,
    width: COL_WIDTH_SMALL,
    align: 'right',
  },
];

export const leaderboardTableColumns: LeaderboardTableColumn[] = entryCells.concat(
  createPlayerCells(0),
  createPlayerCells(1),
  createPlayerCells(2),
  createPlayerCells(3),
);
