import React from 'react';
import clsx from 'clsx';
import { Fab, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { TournamentName } from '../../util/constants';
import { FetchStatus } from '../../util/types';
import theme from '../../theme';
import { getLeaderboardStatus } from '../../store/leaderboard/selectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchLeaderboard } from '../../store/leaderboard/actions';
import { useToggle } from '../../hooks/toggle';

const useStyles = makeStyles({
  topLeft: {
    position: 'fixed',
    top: theme.spacing(5),
    left: theme.spacing(5),
  },
  topRight: {
    position: 'fixed',
    top: theme.spacing(5),
    right: theme.spacing(5),
  },
  bottomLeft: {
    position: 'fixed',
    bottom: theme.spacing(5),
    left: theme.spacing(5),
  },
  bottomRight: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
});

export enum FabPosition {
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
}

interface Props {
  fabPosition?: FabPosition;
  tournamentName: TournamentName;
}

export const RefreshLeaderboardFab: React.FC<Props> = ({ fabPosition, tournamentName }) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const leaderboardStatus = useAppSelector(getLeaderboardStatus);

  const { on: showRefreshMessage, set: setShowRefreshMessage } = useToggle(false);

  const handleRefresh = (): void => {
    dispatch(fetchLeaderboard(tournamentName)).then(() => {
      setShowRefreshMessage(true);
    });
  };

  const handleCloseRefreshMessage = (): void => {
    setShowRefreshMessage(false);
  };

  return (
    <>
      <Fab
        className={clsx({
          [classes.topLeft]: fabPosition === FabPosition.TOP_LEFT,
          [classes.topRight]: fabPosition === FabPosition.TOP_RIGHT,
          [classes.bottomLeft]: fabPosition === FabPosition.BOTTOM_LEFT,
          [classes.bottomRight]: fabPosition === FabPosition.BOTTOM_RIGHT,
        })}
        disabled={leaderboardStatus === FetchStatus.FETCHING}
        onClick={handleRefresh}
        title="Refresh data"
      >
        <Refresh />
      </Fab>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={1500}
        onClose={handleCloseRefreshMessage}
        open={showRefreshMessage}
      >
        <Alert severity="success">
          <Typography>Data refreshed!</Typography>
        </Alert>
      </Snackbar>
    </>
  );
};
