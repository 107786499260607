import { sortedUniq as _sortedUniq, flatten as _flatten } from 'lodash';
import { DateTime } from 'luxon';
import { RootState } from '../store';
import { FetchStatus, LeaderboardEntry } from '../../util/types';

export const getLeaderboard = (state: RootState): LeaderboardEntry[] =>
  state.leaderboard.leaderboardEntries;

export const getLeaderboardStatus = (state: RootState): FetchStatus => state.leaderboard.status;

export const getLeaderboardLastUpdated = (state: RootState): DateTime | null =>
  state.leaderboard.lastUpdated;

export const getLeaderboardTeamNames = (state: RootState): string[] =>
  _sortedUniq(
    state.leaderboard.leaderboardEntries
      .map((leaderboardEntry) => leaderboardEntry.TeamName)
      .sort(),
  );

export const getLeaderboardPlayerNames = (state: RootState): string[] =>
  _sortedUniq(
    _flatten(
      state.leaderboard.leaderboardEntries.map((leaderboardEntry) => leaderboardEntry.Players),
    )
      .map((player) => player.Name)
      .sort(),
  );
