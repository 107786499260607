import React from 'react';
import { makeStyles } from '@material-ui/core';
import theOpenLogo from '../../assets/the_open_logo.png';

const useStyles = makeStyles({
  tournamentLogo: {
    height: 50,
    width: 'auto',
    marginTop: 5,
  },
});

export const TournamentLogo: React.FC = () => {
  const classes = useStyles();

  return <img className={classes.tournamentLogo} src={theOpenLogo} alt="The Open Logo" />;
};
