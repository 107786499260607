import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';

const useStyles = makeStyles({
  select: {
    minWidth: 300,
    marginBottom: 10,
  },
});

interface Props {
  label: string;
  onChange: (selected: string[]) => void;
  selector: (state: RootState) => string[];
}

export const MultiSelect: React.FC<Props> = ({ label, onChange, selector }) => {
  const classes = useStyles();

  const items = useAppSelector(selector);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<unknown>, updatedSelectedItems: string[]) => {
    setSelectedItems(updatedSelectedItems);
    onChange(updatedSelectedItems);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          className={classes.select}
          fullWidth
          multiple
          onChange={handleChange}
          options={items}
          renderInput={(params) => <TextField {...params} label={label} />}
          size="small"
          value={selectedItems}
        />
      </Grid>
    </Grid>
  );
};
